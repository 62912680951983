<template>
  <Banner bg-image="green-bg.jpg" talk-img="suicide-plan.png">
    <template v-slot:title>Help for if you are planning suicide</template>
    <template v-slot:subtitle>
      Things may be looking bleak and your life may not be what you need it to
      be or it has become too stressful. At the moment you may thinking life is
      not worth living anymore, it may seem that the only way to find relief is
      through suicide. You may have a plan to end your suffering.
      <p class="pt-4">
        If you have a plan, or you are doing something that will end your life
        please get help immediately.
      </p>

      <p />
    </template>
    <template v-slot:description>
      Call 911 or visit or ask a trusted friend or family to take nearest
      emergency room.</template
    >
  </Banner>

  <!-- Quick Access section  -->
  <section class="pt-8 mt-8 md:mt-14 md:pb-8 gradient-white">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div
        class="mb-6 text-2xl font-bold text-center call-text md:text-3xl text-blue"
      >
        More ways help if you are planning suicide
      </div>
      <div class="grid grid-cols-1 mt-10 md:grid-cols-2 gap-y-8 gap-x-6">
        <router-link :to="{ name: 'CallACrisisHotline' }">
          <QuickAccess
            arrow="text-green"
            text="Call a crisis hotline"
            img="call-a-crisis-hotline.svg"
          />
        </router-link>
        <router-link :to="{ name: 'FindACounselor' }">
          <QuickAccess
            arrow="text-yellow"
            text="Find a counselor"
            img="find-a-counselor.svg"
          />
        </router-link>
      </div>
    </div>
  </section>
  <section class="talk-sect md:my-12 md:py-14 gradient-white">
    <div class="max-w-6xl px-4 mx-auto sm:px-6">
      <div class="max-w-6xl px-4 mx-auto sm:px-6">
        <div class="text-2xl font-bold py-9 md:text-3xl text-blue">
          How can I help myself now?
        </div>
        <p class="mb-6 text-xl md:text-2xl text-blue">
          The problems you are facing may seem too great to manage at the
          moment, but there are ways to cope
        </p>
        <p class="mt-6 text-justify">
          You might be feeling like your problems are all-consuming. You might
          be completely overcome by intense emotion and thoughts that this is
          too challenging to overcome. Lately, you may be finding it difficult
          to think and act rationally, and even function in a normal way. You
          may even be feeling frozen or paralyzed or like you are “underwater”
          after a rough wave. Your experience may have left you feeling blue,
          miserable, depressed, scared, empty, or worthless. You may not know
          which way is up or what way to “swim.” You may be feeling alone right
          now.
        </p>
        <p class="mt-6 text-justify text-blue">
          There is no single way or “right” way to cope with thoughts of suicide
          or ask for help. People will experience thoughts of suicide
          differently and therefore, coping with these thoughts will be
          different for every individual.
        </p>
      </div>
      <div class="mt-10">
        <div class="text-xl font-bold md:text-2xl text-blue">
          Ideas to help you cope
        </div>
      </div>
      <div class="pt-8">
        <!-- 1 -->
        <Collapsible>
          <template v-slot:header>
            <div class="px-8 text-2xl">Stay safe now</div>
          </template>

          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <div class="mb-4 text-xl font-bold md:text-2xl">
                  Get through the next five minutes
                </div>
                <p>
                  Taking things minute by minute can help make things more
                  bearable. Reward yourself each time five minutes have passed.
                </p>
                <p>
                  Use our
                  <router-link
                    class="underline text-green"
                    :to="{ name: 'PracticeGroundingTechniques' }"
                    >grounding techniques</router-link
                  >
                  to help you through the next few minutes.
                </p>
                <div class="my-4 text-xl font-bold md:text-2xl">
                  Remove anything you could use to harm yourself
                </div>
                <p>
                  Remove any items or things you could use to harm yourself or
                  ask someone else to remove these for you. If you’re in an
                  unsafe location, move away to somewhere safer.
                </p>
                <div class="my-4 text-xl font-bold md:text-2xl">
                  Follow your safety plan or crisis plan
                </div>
                <p>
                  If you have a
                  <router-link
                    class="underline text-green"
                    :to="{ name: 'CreateASafetyPlan' }"
                  >
                    safety plan
                  </router-link>
                  in place, follow it.
                </p>
                <p>
                  These plans are a good way to save ideas for helping yourself
                  when you feel unwell.
                </p>
                <div class="mt-6 text-right">
                  <router-link
                    @click="
                      this.$gtm.trackEvent({
                        event: 'gtm.btnClick',
                        category: 'button',
                        btnTitle: 'CreateASafetyPlan-btn',
                        label: 'Learn how to create a safety plan',
                        action: 'Clicked on',
                      })
                    "
                    class="font-bold text-green"
                    :to="{ name: 'CreateASafetyPlan' }"
                  >
                    Learn how to create a safety plan
                    <svg
                      class="inline-block"
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.922"
                      height="28"
                      viewBox="0 0 10.922 28"
                    >
                      <path
                        id="Path_4072"
                        data-name="Path 4072"
                        d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                        transform="translate(-831.098 -480.029)"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </router-link>
                </div>
                <div class="my-4 text-xl font-bold md:text-2xl">
                  Tell someone how you’re feeling
                </div>
                <p>
                  Whether it’s a friend, family member or even a pet, telling
                  someone else how you’re feeling can help you feel less alone
                  and more in control.
                </p>
                <div class="my-6 text-right">
                  <router-link
                    @click="
                      this.$gtm.trackEvent({
                        event: 'gtm.btnClick',
                        category: 'button',
                        btnTitle: 'LearnHowToAskForHelp-btn',
                        label: 'Learn tips for talking to someone',
                        action: 'Clicked on',
                      })
                    "
                    class="font-bold text-green"
                    :to="{ name: 'LearnHowToAskForHelp' }"
                  >
                    Learn tips for talking to someone
                    <svg
                      class="inline-block"
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.922"
                      height="28"
                      viewBox="0 0 10.922 28"
                    >
                      <path
                        id="Path_4072"
                        data-name="Path 4072"
                        d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                        transform="translate(-831.098 -480.029)"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </router-link>
                </div>
                <div class="my-4 text-xl font-bold md:text-2xl call-text">
                  Go to a safe place
                </div>
                <p>
                  Go to a place where you feel safe. Here is a list of places
                  you could try:
                </p>
                <ul class="about-ul">
                  <li>Mental health or spiritual center</li>
                  <li>Crisis center</li>
                  <li>A friend’s or relative’s house</li>
                  <li>Library</li>
                </ul>
                <div class="my-6 text-right">
                  <router-link
                    @click="
                      this.$gtm.trackEvent({
                        event: 'gtm.btnClick',
                        category: 'button',
                        btnTitle: 'CallACrisisHotline-btn',
                        label: 'Contact information for crisis centers',
                        action: 'Clicked on',
                      })
                    "
                    class="font-bold text-green"
                    :to="{ name: 'CallACrisisHotline' }"
                  >
                    Contact information for crisis centers
                    <svg
                      class="inline-block"
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.922"
                      height="28"
                      viewBox="0 0 10.922 28"
                    >
                      <path
                        id="Path_4072"
                        data-name="Path 4072"
                        d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                        transform="translate(-831.098 -480.029)"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </router-link>
                </div>
              </div>
            </div>
          </template>
        </Collapsible>

        <br />
        <!-- 2 -->
        <Collapsible>
          <template v-slot:header>
            <div class="px-8 text-2xl">Distract yourself</div>
          </template>
          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <div class="mb-6 text-xl font-bold call-text md:text-2xl">
                  Try some coping techniques
                </div>
                <p>
                  If have a strong desire or intention to harm yourself, you
                  could try using self-harm coping techniques. For example, you
                  could:
                </p>
                <ul class="my-6 about-ul">
                  <li>Tear something up into hundreds of pieces.</li>
                  <li>
                    Take a very cold shower or bath, while playing your favorite
                    songs.
                  </li>
                  <li>Draw or paint.</li>
                  <li>Listen to music.</li>
                  <li>Sing like no one is listening.</li>
                  <li>Dance like no one is watching.</li>
                </ul>
                <div class="my-6 text-xl font-bold call-text md:text-2xl">
                  Practice Indigenous cultural healing practices
                </div>
                <p>
                  Perform dances, ceremonies, and spiritual practices, such as
                  powwows, sweetgrass ceremonies, sweat lodges and drumming
                  circles.
                </p>

                <div class="my-6 text-xl font-bold call-text md:text-2xl">
                  Focus on breathing
                </div>
                <p>
                  Take long, deep breaths. Breathing out for longer than you
                  breathe in can help you feel calmer.
                </p>
                <div class="mt-6 text-right">
                  <router-link
                    @click="
                      this.$gtm.trackEvent({
                        event: 'gtm.btnClick',
                        category: 'button',
                        btnTitle: 'PracticeBreathingExercises-btn',
                        label: 'Try breathing exercises',
                        action: 'Clicked on',
                      })
                    "
                    class="font-bold text-green"
                    :to="{ name: 'PracticeBreathingExercises' }"
                  >
                    Try breathing exercises
                    <svg
                      class="inline-block"
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.922"
                      height="28"
                      viewBox="0 0 10.922 28"
                    >
                      <path
                        id="Path_4072"
                        data-name="Path 4072"
                        d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                        transform="translate(-831.098 -480.029)"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </router-link>
                </div>
                <div class="my-6 text-xl font-bold call-text md:text-2xl">
                  Focus on your sense of smell
                </div>
                <p>
                  Go to a coffee shop, a soap shop, perfume shop, or turn on a
                  diffuser with scented oils like lavender, tea tree or sage
                  oil. A scented candle would also work.
                </p>
                <div class="my-6 text-xl font-bold call-text md:text-2xl">
                  Focus on your sense of taste
                </div>
                <p>
                  Treat yourself to a food you like and pay close attention to
                  how it tastes, how it feels in your mouth and what you like
                  about it.
                </p>
                <div class="my-6 text-xl font-bold call-text md:text-2xl">
                  Look after your needs
                </div>
                <p>If you can, try doing the following:</p>
                <ul class="about-ul">
                  <li>Get a glass of water.</li>
                  <li>Eat something.</li>
                  <li>
                    Name the emotions you are feeling at the moment and allow
                    yourself to feel what you are feeling.
                  </li>
                </ul>
                <div class="my-6 text-xl font-bold call-text md:text-2xl">
                  Go outside
                </div>
                <p>
                  If you’re feeling numb, go outside and feel the rain, sun or
                  wind against your skin. This can help you to feel more
                  connected to your body.
                </p>
                <ul class="about-ul">
                  <li>Walk in a green space like a park.</li>
                  <li>Listen to nature.</li>
                </ul>
                <div class="my-6 text-xl font-bold call-text md:text-2xl">
                  Avoid substances that can make you feel worse
                </div>
                <p class="pb-6">
                  Try to avoid drinking alcohol or taking recreational drugs, as
                  this can make you feel worse.
                </p>
              </div>
            </div>
          </template>
        </Collapsible>

        <br />
        <!-- 3 -->
        <Collapsible>
          <template v-slot:header>
            <div class="px-8 text-2xl">Challenge your thoughts</div>
          </template>
          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <div class="mb-6 text-xl font-bold call-text md:text-2xl">
                  Understand your thought patterns
                </div>
                <p>
                  There are many different types of thinking patterns we might
                  use when we have a desire to die. Here are some ways to
                  understand or even interrupt these patterns:
                </p>
                <ul class="my-4 about-ul">
                  <li>
                    Practice a mindfulness activity like
                    <router-link
                      class="underline text-green"
                      :to="{ name: 'PracticeGuidedMeditation' }"
                    >
                      guided meditation</router-link
                    >,
                    <router-link
                      class="underline text-green"
                      :to="{ name: 'PracticeBreathingExercises' }"
                    >
                      breathing exercises</router-link
                    >

                    or yoga.
                  </li>
                  <li>
                    Start a
                    <a
                      href="/files/thought-diary.pdf"
                      download
                      class="underline text-green"
                      >thought diary</a
                    >.
                  </li>
                  <li>Focus on things you are grateful for.</li>
                  <li>
                    Seek professional help. Here are some
                    <router-link
                      class="underline text-green"
                      :to="{ name: 'FindACounselor' }"
                    >
                      professionals</router-link
                    >
                    you can contact.
                  </li>
                  <li>
                    Write down what you’re looking forward to. You might look
                    forward to eating your favourite meal, seeing a loved one or
                    catching up on the next episode of a TV show.
                  </li>
                  <li>
                    Make plans to do something you enjoy today, tomorrow or in
                    the near future. Plans don’t have to be big or expensive.
                  </li>
                </ul>

                <div class="my-6 text-xl font-bold call-text md:text-2xl">
                  Write it down
                </div>
                <p>
                  It can help to write down your thoughts when you’re feeling
                  negative. The key is to find a way that works for you. Whether
                  that’s jotting them down in a notebook or recording notes on
                  your phone. It’s like you’re transferring your thoughts out of
                  your head and into a journal. When making notes, try to put
                  down your thoughts under three headings:
                </p>
                <ul class="my-4 about-ul">
                  <li>What’s the situation?</li>
                  <li>What am I thinking or telling myself?</li>
                  <li>How down/anxious or bad do I feel on a scale of 1–10?</li>
                </ul>
                <div class="my-6 text-right">
                  <router-link
                    class="font-bold text-green"
                    :to="{ name: 'ChallengeYourThoughts' }"
                  >
                    Practice challenging your thoughts
                    <svg
                      class="inline-block"
                      xmlns="http://www.w3.org/2000/svg"
                      width="10.922"
                      height="28"
                      viewBox="0 0 10.922 28"
                    >
                      <path
                        id="Path_4072"
                        data-name="Path 4072"
                        d="M831.1,508.029l8.712-8.711a7.544,7.544,0,0,0,0-10.67l-8.62-8.62a26.475,26.475,0,0,1-.091,28Z"
                        transform="translate(-831.098 -480.029)"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </router-link>
                </div>
              </div>
            </div>
          </template>
        </Collapsible>

        <br />
        <!-- 4 -->
        <Collapsible>
          <template v-slot:header>
            <div class="px-8 text-2xl">Be kind to yourself</div>
          </template>
          <template v-slot:content>
            <div class="px-8 py-4 bg-blue-100">
              <div class="faq-tab-content">
                <div class="mb-6 text-xl font-bold call-text md:text-2xl">
                  Act like your own best friend
                </div>
                <p>
                  Talk to yourself as if you were talking to a good friend. And
                  try doing something nice for yourself. This could even be
                  something small like:
                </p>
                <ul class="my-4 about-ul">
                  <li>Have a bath.</li>
                  <li>
                    Wrap yourself in a blanket and watch your favourite film.
                  </li>
                </ul>
                <p class="pb-6">
                  These ideas may seem silly but it can be easy to forget to do
                  something nice for yourself.
                </p>
              </div>
            </div>
          </template>
        </Collapsible>
      </div>
    </div>
  </section>

  <FooterCard img="stay-safe.png" text="Thoughts" name="Thoughts">
    <template v-slot:header> Stay safe and learn more </template>
    <template v-slot:content>
      To provide the most appropriate support and stay safe you can learn more
      about suicide intent or learn how to get help when having a plan for
      suicide.
    </template>
    <template v-slot:btn>
      <GreenBtn name="Intent" text="Intent" />
    </template>
  </FooterCard>
</template>

<script>
import Banner from "@/components/Banner";
import QuickAccess from "@/components/QuickAccess";
import Collapsible from "@/components/Collapsible";
import FooterCard from "@/components/FooterCard";
import GreenBtn from "@/components/GreenBtn";
export default {
  name: "PlanningSuicide",
  components: {
    Banner,
    QuickAccess,
    Collapsible,
    GreenBtn,
    FooterCard,
  },
};
</script>

<style scoped>
.gradient-white {
  background: linear-gradient(to bottom, #f8f8f8, #fff);
}
ul.about-ul li {
  padding: 8px 0 8px 50px;

  position: relative;
}
ul.about-ul li::after {
  content: "";
  background: #22b46e;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: absolute;
  left: 15px;
  top: 15px;
}
</style>
